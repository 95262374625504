import mvtDialog from '@/common/components/mvtdialog/mvt-dialog.vue';
import loadDialogError from '@/common/components/mvtdialogcenter/loaddialogerror.vue';
import loadingDialog from '@/common/components/mvtdialogcenter/loadingdialog.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getGa4Trigger } from '../../../../widget/spec/ga4helper';
var timer = null;
var timerOpen = null;
var tipTimer = null;
export default {
    name: 'mvtDialogCenter',
    inject: ['$eventBus'],
    components: {
        mvtDialog,
        loadDialogError,
        loadingDialog,
    },
    data() {
        return {
            message: '',
            showTip: false,
            componentName: '',
            componentOpt: {
                type: '',
            },
            componentTemplate: '',
            componentTrigger: '',
            initalApple: false,
            dialogCache: {},
            dlgRetryCount: 0,
            requestName: '',
        };
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getSplit', 'getPrevHotlead', 'getErrorHtml', 'removeAds']),
        ...mapGetters('dpp', ['dpp']),
    },
    methods: {
        ...mapActions('glb', ['loginForGoogleAccount', 'loginForAppleAccount', 'signup', 'checkUserByEmailId']),
        ...mapMutations('glb', ['resetPrevHotlead']),
        signUpMixin(userEmail, requestName, phone, name) {
            this.requestName = requestName;
            this.glb.authFormState = 'submitting';

            this.checkUserByEmailId({
                email: userEmail,
            })
                .then((res) => {
                    let status = res && res.status;
                    if (status.code === 0 && res.data.id) {
                        this.glb.authErrorMessage = '';
                        this.glb.authFormState = '';
                        if (res.data.googleConnected) {
                            this.$eventBus.$emit('dialog-center', {
                                name: 'mvtAuthenticate',
                                opt: {
                                    type: 'mvtWelcomeBack',
                                    email: userEmail,
                                    loginType: 'google',
                                    loginMessage: 'You signed up with your Google account',
                                    requestName: requestName,
                                    trigger: this.componentTrigger,
                                },
                                trigger: this.componentTrigger,
                            });
                        } else if (res.data.appleConnected) {
                            this.$eventBus.$emit('dialog-center', {
                                name: 'mvtAuthenticate',
                                opt: {
                                    type: 'mvtWelcomeBack',
                                    email: userEmail,
                                    loginType: 'apple',
                                    loginMessage: 'You originally signed up with Apple',
                                    requestName: requestName,
                                    trigger: this.componentTrigger,
                                },
                                trigger: this.componentTrigger,
                            });
                        } else {
                            this.$eventBus.$emit('dialog-center', {
                                name: 'mvtAuthenticate',
                                opt: {
                                    type: 'mvtEmailLogin',
                                    loginType: 'email',
                                    email: userEmail,
                                    isUserExist: true,
                                    requestName: requestName,
                                    phone: phone,
                                    name: name,
                                },
                                trigger: this.componentTrigger,
                            });
                        }
                    } else {
                        this.signUp(userEmail, requestName, phone, name);
                    }
                })
                .catch((err) => {
                    this.glb.authFormState = '';
                });
        },
        signUp(userEmail, requestName, phone, name) {
            const isSellerPage = ['vsell', 'vsellfast', 'vhomeWorth', 'vhomesalecalc', 'vhomeowner', 'vhomeserviceslanding'].includes(this.glb.pageType);
            const userType = isSellerPage ? 'seller' : this.glb.pageInfo.userType;

            this.glb.authFormState = 'submitting';

            this.signup({
                email: userEmail,
                userByEmail: true,
                phone: phone,
                firstName: name,
                userType,
                trigger: getGa4Trigger(this.componentTrigger, requestName),
            })
                .then((resp) => {
                    let status2 = resp && resp.status;
                    if (status2 && status2.code === 0) {
                        this.glb.authFormState = 'submitted';
                        this.glb.authErrorMessage = '';
                        this.$eventBus.$emit('eventbus-authenticated', requestName);
                    } else {
                        this.glb.authFormState = 'rejected';
                        this.glb.authErrorMessage = this.getErrorHtml(status2);
                    }
                })
                .catch((err) => {
                    this.glb.authFormState = '';
                    this.glb.authErrorMessage = '';
                });
        },
        afterLoginAndCloseDialog() {
            if (timer) {
                clearTimeout(timer);
            }
            let hasDialog = $('#body').hasClass('dialog-active') || $('#body').hasClass('popownup-active');
            if (hasDialog) {
                this.$eventBus.$once('eventbus-dialog-close', () => {
                    timer = setTimeout(() => {
                        this.afterLoginAndCloseDialog();
                    }, 2000);
                });
            } else {
                setTimeout(
                    () => {
                        clearTimeout(timer);
                        this.dialogAllClosed();
                    },
                    timer ? 1000 - timer : 3000
                );
            }
        },
        getDialog(name) {
            let loader, callback;
            switch (name) {
                case 'mvtSearchResult':
                    loader = import(/* webpackChunkName: "dlg.tpls.searchresult" */ '@/common/components/mvtsearchresult/mvt-searchresult.vue');
                    break;
                case 'mvtGeoSearchResult':
                    loader = import(/* webpackChunkName: "dlg.tpls.geosearchresult" */ '@/common/components/mvtgeosearchresult/mvt-geosearchresult.vue');
                    break;
                case 'mvtAuthenticate':
                    loader = import(/* webpackChunkName: "dlg.tpls.authenticate" */ '@/common/components/mvtauthenticate/mvt-authenticate.vue');
                    break;
                case 'mvtHotleadForm':
                    loader = import(/* webpackChunkName: "dlg.tpls.hotleadform" */ '@/common/components/mvthotleadform/mvt-hotleadform.vue');
                    break;
                case 'mvtConfirm':
                    loader = import(/* webpackChunkName: "dlg.tpls.confirm" */ '@/common/components/mvtconfirm/mvt-confirm.vue');
                    break;
                case 'mvtConfirmAuth':
                    loader = import(/* webpackChunkName: "dlg.tpls.confirmauth" */ '@/common/components/mvtconfirm/mvt-confirmauth.vue');
                    break;
                case 'mvtMortgageForm':
                    loader = import(/* webpackChunkName: "dlg.tpls.mortgageform" */ '@/common/components/mvtmortgageform/mvt-mortgageform.vue');
                    break;
                case 'mvtSearchSaveConfirm':
                    loader = import(/* webpackChunkName: "dlg.tpls.searchsaveconfirm" */ '@/common/components/mvtsearchsaveconfirm/mvt-searchsaveconfirm.vue');
                    break;
                case 'mvtSendEmail':
                    loader = import(/* webpackChunkName: "dlg.tpls.sendemail" */ '@/common/components/mvtsendemail/mvt-sendemail.vue');
                    break;
                case 'mvtSellLead':
                    loader = import(/* webpackChunkName: "dlg.tpls.selllead" */ '@/common/components/mvtselllead/mvt-selllead.vue');
                    break;
                case 'mvtCashOffer':
                    loader = import(/* webpackChunkName: "dlg.tpls.cashoffer" */ '@/common/components/mvtcashoffer/mvt-cashoffer.vue');
                    break;
                case 'mvtConfirmSell':
                    loader = import(/* webpackChunkName: "dlg.tpls.confirmsell" */ '@/common/components/mvtconfirmsell/mvt-confirmsell.vue');
                    break;
                case 'dppPopAvm':
                    loader = import(/* webpackChunkName: "dlg.tpls.popavm" */ '@/dpp/components/dpppop/dpp-popavm.vue');
                    break;
                case 'dppPopEst':
                    loader = import(/* webpackChunkName: "dlg.tpls.popest" */ '@/dpp/components/dpppop/dpp-popest.vue');
                    break;
                case 'equityMortgagePop':
                    loader = import(/* webpackChunkName: "dlg.tpls.popup" */ '@/home-owner/components/equitymortgagepopup/equity-mortgage-popup.vue');
                    break;
                case 'propertyMortgageRates':
                    loader = import(/* webpackChunkName: "dlg.tpls.mortgagerates" */ '@/dpp/components/propertymortgagerates/property-mortgagerates.vue');
                    break;
                case 'editProfile':
                    loader = import(/* webpackChunkName: "dlg.tpls.profile" */ '@/mymovoto/components/editprofile/edit-profile.vue');
                    break;
                case 'mortgageForm':
                    loader = import(/* webpackChunkName: "dlg.tpls.form" */ '@/home-owner/components/mortgageform/mortgage-form.vue');
                    break;
                case 'resetPassword':
                    loader = import(/* webpackChunkName: "dlg.tpls.password" */ '@/mymovoto/components/password/reset-password.vue');
                    break;
                case 'forgotPassword':
                    loader = import(/* webpackChunkName: "dlg.tpls.password" */ '@/mymovoto/components/password/forgot-password.vue');
                    break;
                case 'editSavedSearch':
                    loader = import(/* webpackChunkName: "dlg.tpls.search" */ '@/mymovoto/components/editsavedsearch/edit-saved-search.vue');
                    break;
                case 'removeSavedSearch':
                    loader = import(/* webpackChunkName: "dlg.tpls.search" */ '@/mymovoto/components/removesavedsearch/remove-saved-search.vue');
                    break;
                case 'agentJoin':
                    loader = import(/* webpackChunkName: "dlg.tpls.join" */ '@/agent/components/agentjoin/agent-join.vue');
                    break;
                case 'contactAgent':
                    loader = import(/* webpackChunkName: "dlg.tpls.agent" */ '@/msp/components/contactagent/contact-agent.vue');
                    break;
                case 'mvtConfirmCER':
                    loader = import(/* webpackChunkName: "dlg.tpls.confirmcer" */ '@/common/components/mvt-confirmcer/mvt-confirmcer.vue');
                    break;
                case 'unsubscribeConfirm':
                    loader = import(/* webpackChunkName: "dlg.tpls.confirm" */ '@/feed/components/unsubscribeconfirm/unsubscribe-confirm.vue');
                    break;
                case 'myConcierge':
                    loader = import(/* webpackChunkName: "mymvt.dlg.tpls.concierge" */ '@/mymovoto/components/agent/concierge/concierge.vue');
                    break;
                case 'removeHome':
                    loader = import(/* webpackChunkName: "mymvt.dlg.tpls.home" */ '@/home-owner/components/removehomeconfirmation/remove-home.vue');
                    break;
                case 'agentFeedback':
                    loader = import(/* webpackChunkName: "mymvt.dlg.tpls.feedback" */ '@/mymovoto/components/agent/agentfeedback/agent-feedback.vue');
                    break;
                case 'conciergeConfirm':
                    loader = import(/* webpackChunkName: "mymvt.dlg.tpls.confirm" */ '@/mymovoto/components/agent/conciergeconfirm/concierge-confirm.vue');
                    break;
                case 'favoritePR':
                    loader = import(/* webpackChunkName: "dlg.tpls.favoritepr" */ '@/dpp/components/propertyfavoritepr/property-favoritepr.vue');
                    break;
                case 'changeAgent':
                    loader = import(/* webpackChunkName: "dlg.tpls.agentselection" */ '@/dpp/components/dppagentselection/dpp-agentselection.vue');
                    break;
                case 'mvtIntentfulTYP':
                    loader = import(/* webpackChunkName: "dlg.tpls.intentfultyp" */ '@/common/components/mvtintentfultyp/mvt-intentfultyp.vue');
                    break;
                case '3wayPayment':
                    loader = import(/* webpackChunkName: "dlg.tpls.mortgage" */ '@/dpp/components/propertymortgage/property-mortgage.vue');
                    break;
                case 'dppIframePop':
                    loader = import(/* webpackChunkName: "dlg.tpls.iframepop" */ '@/dpp/components/dppiframepop/dpp-iframepop.vue');
                    break;
                case 'mrcMortgageRates':
                    loader = import(/* webpackChunkName: "dlg.tpls.mortgagerates" */ '@/common/components/mvtmortgage/mrcmortgagerates/mrc-mortgagerates.vue');
                    break;
                case 'mvtConfirmSave':
                    loader = import(/* webpackChunkName: "dlg.tpls.confirmsave" */ '@/common/components/mvtconfirmsave/mvt-confirmsave.vue');
                    break;
                case 'mvtAddHomeOwner':
                    loader = import(/* webpackChunkName: "dlg.tpls.addhomeowner" */ '@/common/components/mvtauthenticate/mvt-addhomeowner.vue');
                    break;
                case 'mvtDisclaimer':
                    loader = import(/* webpackChunkName: "dlg.tpls.disclaimer" */ '@/common/components/mvtdisclaimer/mvt-disclaimer.vue');
                    break;
                case 'checkListPros':
                    loader = import(/* webpackChunkName: "dlg.tpls.pros" */ '@/home-owner/components/checklistpros/checklist-pros.vue');
            }
            return { loader, callback };
        },
        showDialog(component, name, options, trigger) {
            if (timer) {
                clearTimeout(timer);
            }
            let opt = options || {};
            opt.trigger = trigger;
            if (trigger) {
                this.componentTrigger = trigger;
            } else {
                this.componentTrigger = this.$options.name;
            }
            this.componentOpt = opt;
            this.componentName = name;
            this.componentTemplate = component.template;
            component.callback && component.callback();
            this.$refs.dialog.show(name, trigger, opt);
        },
        show: async function (name, opt, trigger) {
            if (!this.dialogCache[name]) {
                this.showDialog({ template: 'loadingDialog' }, 'loadingDialog', { ...opt, autoClose: false }, trigger);
                // await yieldToMain();
                const { loader = null, callback } = this.getDialog(name);
                return (
                    loader &&
                    loader
                        .then((module) => {
                            this.dialogCache[name] = {
                                template: module.default,
                                callback,
                            };
                            return this.showDialog(this.dialogCache[name], name, opt, trigger);
                        })
                        .catch((e) => {
                            this.dlgRetryCount++;
                            opt && (opt.dlgRetryCount = this.dlgRetryCount);
                            window.sendNodeLog('dlgLazyFail', window.location.href, this.dlgRetryCount, {
                                tpl: name,
                                trigger: trigger,
                                message: e && e.message + ', stack:' + e.stack,
                                tid: this.glb.ip,
                                mid: this.glb.movotoDeviceID,
                            });
                            return this.showDialog({ template: 'loadDialogError' }, 'loadDialogError', opt, trigger);
                        })
                );
            }
            this.showDialog(this.dialogCache[name], name, opt, trigger);
        },
        hasValidGeo(geo) {
            return geo && geo.address != 'Undisclosed' && geo.city && geo.state && geo.zipcode;
        },
        getNextPopName(trigger, requestName) {
            const firstOptions = this.getPrevHotlead.firstOptions || {};
            const firstHotlead = this.getPrevHotlead.firstHotlead || {};
            const history = this.getPrevHotlead.popHistory;
            const hotlead = this.getPrevHotlead.hotlead || {};
            const hasSubmitLead = this.getPrevHotlead.hasSubmitLead;
            const isSellLead = !!this.glb.sellLeadType.find((item) => {
                if (item.includes('_')) {
                    return item === firstHotlead.type;
                }
                return item.indexOf(firstHotlead.type) > -1;
            });
            const isPrevSellLead = !!this.glb.sellLeadType.find((item) => {
                if (item.includes('_')) {
                    return item === hotlead.type;
                }
                return item.indexOf(hotlead.type) > -1;
            });

            const isValidGeo = firstOptions.propertyData && firstOptions.propertyData.geo && this.hasValidGeo(firstOptions.propertyData.geo);
            const isRentalLead = firstOptions.propertyData && firstOptions.propertyData.isRentals;
            const isHotleadForm = ['mvtHotleadForm', 'mvtCashOffer', 'mvtSellLead'].includes(trigger);
            const hasShowSellLead = this.getPrevHotlead.popHistory.filter((e) => ['mvtCashOffer', 'mvtSellLead'].includes(e)).length > 0;
            const isAllianceRealty = requestName === 'AllianceRealty';

            let popName;
            if (isHotleadForm) {
                if (hasSubmitLead) {
                    const hasSendSellLead =
                        $.getStorage('hasSendSellLead') ||
                        !!this.glb.sellLeadType.find((item) => {
                            if (item.includes('_')) {
                                return item === hotlead.type;
                            }
                            return item.indexOf(hotlead.type) > -1;
                        });
                    if (hasSendSellLead) {
                        $.setStorage('hasSendSellLead', 1);
                    }
                    if (firstOptions.isVeteran || isRentalLead) {
                        popName = 'mvtConfirm';
                    } else if (isAllianceRealty) {
                        popName = 'mvtIntentfulTYP';
                    } else if (firstOptions.trigger === 'propertyTour' && !history.includes('mvtConfirmThankYou')) {
                        popName = 'mvtConfirm';
                    } else if ((isPrevSellLead || trigger === 'mvtSellLead') && this.getSplit('movoto-mortgage-CW-10250', 'on8')) {
                        popName = 'mvtIntentfulTYP';
                    } else if (isSellLead) {
                        popName = 'mvtConfirmSell';
                    } else if (!hasSendSellLead && !hasShowSellLead) {
                        popName = 'mvtSellLead';
                    } else if (!isValidGeo) {
                        popName = 'mvtConfirm';
                    } else if (this.getSplit('movoto-mortgage-CW-10250')) {
                        if (
                            this.getSplit('movoto-mortgage-CW-10250', 'on1') ||
                            (this.getSplit('movoto-mortgage-CW-10250', 'default') && !['LAND', 'MOBILE'].includes(firstOptions.propertyData.propertyType))
                        ) {
                            popName = '3wayPayment';
                        } else if (this.getSplit('movoto-mortgage-CW-10250', 'on2') || this.getSplit('movoto-mortgage-CW-10250', 'on7')) {
                            popName = 'mvtIntentfulTYP';
                        } else if (this.getSplit('movoto-mortgage-CW-10250', 'on3')) {
                            popName = 'mvtMortgageForm';
                        } else if (this.getSplit('movoto-mortgage-CW-10250', 'on4') || this.getSplit('movoto-mortgage-CW-10250', 'on6')) {
                            if (this.glb.useMRCPopAsDefaultPop) {
                                popName = 'mrcMortgageRates';
                            } else {
                                popName = 'mvtConfirm';
                            }
                        } else if (this.getSplit('movoto-mortgage-CW-10250', 'on5')) {
                            this.$eventBus.$emit('eventbus-own-up');
                            this.$eventBus.$emit('ga', { ec: 'partner_mortgage_postre_session', en: 'long_form', el: 'OwnUp', props: {} });
                            popName = 'mvtOwnup';
                        } else {
                            if (this.glb.useMRCPopAsDefaultPop) {
                                popName = 'mrcMortgageRates';
                            } else {
                                popName = 'mvtConfirm';
                            }
                        }
                    } else if (this.glb.useMRCPopAsDefaultPop) {
                        popName = 'mrcMortgageRates';
                    } else {
                        popName = 'mvtConfirm';
                    }
                } else {
                    popName = '';
                }
            } else if (trigger === 'mvtPopOwnup') {
                popName = 'autoClose';
            } else if (trigger === 'mvtConfirmSell') {
                if (this.glb.useMRCPopAsDefaultPop) {
                    popName = 'mrcMortgageRates';
                } else {
                    popName = 'mvtConfirm';
                }
            } else if (trigger === 'mvtMortgageForm') {
                popName = 'mvtConfirm';
            } else if (trigger === 'mvtConfirmThankYou') {
                if (firstOptions.trigger === 'propertyTour' && !hasShowSellLead) {
                    popName = 'mvtSellLead';
                } else {
                    popName = 'autoClose';
                }
            } else if (trigger === 'propertyMortgage') {
                popName = '';
            } else if (trigger === 'mrcMortgageRates') {
                if (!isSellLead && hasSubmitLead) {
                    popName = 'mvtConfirm';
                } else {
                    popName = 'autoClose';
                }
            } else if (trigger === 'mvtAuthenticate') {
                if (requestName !== 'addclaimhome' && !this.glb.user.activated) {
                    popName = 'mvtAddHomeOwner';
                } else {
                    popName = 'autoClose';
                }
            }

            return popName;
        },
        nextHotleadStep({ trigger, requestName }) {
            let popName = this.getNextPopName(trigger, requestName);
            let isAllianceRealty = requestName === 'AllianceRealty';
            //we force put property-mortgage into dialog center and rename it called '3wayPayment', need check the requirement and understand why we are doing that way
            if (popName === 'autoClose') {
                this.$refs.dialog.hide();
                return;
            }
            if (timerOpen) {
                clearTimeout(timerOpen);
            }
            timerOpen = setTimeout(() => {
                if (popName === '3wayPayment') {
                    this.$eventBus.$emit('dialog-center', {
                        name: '3wayPayment',
                        trigger: trigger,
                        opt: {
                            showRefinance: true,
                            pid: 'movoto_postreapi',
                            triggerId: 'postremovotoapi',
                            showNewTitle: true,
                        },
                    });
                    return;
                }

                if (popName === 'mvtConfirm') {
                    let firstOpt = this.getPrevHotlead.firstOptions || {};
                    this.$eventBus.$emit('dialog-center', {
                        name: 'mvtConfirm',
                        opt: {
                            title: 'THANK YOU!',
                            message: 'We have sent your request. Expect a call/email shortly.',
                            propertyData: firstOpt.propertyData,
                            noSecondaryLead: firstOpt.noSecondaryLead,
                            componentName: 'mvtConfirmThankYou',
                            hotlead: this.getPrevHotlead.firstHotlead,
                            userType: firstOpt.userType,
                            isOJOAgentLead: firstOpt.hasOJOAgent,
                            isVeteranLead: firstOpt.showVeteranThankYou,
                            isRequestTourLead: trigger === 'mvtHotleadForm' && firstOpt.trigger === 'propertyTour',
                            hotleadCount: firstOpt.hotleadCount,
                            veteransType: firstOpt.veteransType,
                            skip3rdPartyPop: firstOpt.skip3rdPartyPop,
                        },
                        trigger: this.componentTrigger,
                    });
                    return;
                }

                if (popName === 'mrcMortgageRates') {
                    let utm_campaign = trigger === 'mvtConfirmSell' ? 'sellerTYP' : this.getSplit('movoto-mortgage-CW-10250', 'on6') ? 'postrelead_mobile' : 'postrelead';
                    this.$eventBus.$emit('dialog-center', {
                        name: 'mrcMortgageRates',
                        trigger: this.componentTrigger,
                        opt: {
                            showNormalMrc: false,
                            utm_campaign: utm_campaign,
                            ga: this.getSplit('movoto-mortgage-CW-10250', 'on6') ? 'partner_mortgage_postre_session_mobile' : 'partner_mortgage_postre_session',
                        },
                    });
                    return;
                }

                if (popName === 'mvtIntentfulTYP') {
                    let options = {};
                    if (isAllianceRealty) {
                        options = {
                            subText: '(NMLS # 1907)',
                            el: 'Alliance Realty TYP',
                            cta_el: 'Alliance Realty',
                            treatment: 'alliancerealty',
                        };
                    } else if (this.getSplit('movoto-mortgage-CW-10250', 'on2')) {
                        options = {
                            subText: 'While you wait, want to find a lender to get preapproved for a mortgage?',
                            el: 'Cordless CPC',
                            cta_el: 'Cordless Post RE Click',
                            treatment: 'cw-10250_on2',
                        };
                    } else if (this.getSplit('movoto-mortgage-CW-10250', 'on5')) {
                        options = {
                            subText: '',
                            el: 'OwnUp',
                            cta_el: 'OwnUp',
                            treatment: 'cw-10250_on5',
                        };
                    } else if (this.getSplit('movoto-mortgage-CW-10250', 'on7')) {
                        options = {
                            subText: `While you wait, want to see if there's a lender who can help you get preapproved?`,
                            el: 'MRC Branded TYP',
                            cta_el: 'MRC Post RE Click',
                            treatment: 'cw-10250_on7',
                        };
                    } else if (this.getSplit('movoto-mortgage-CW-10250', 'on8')) {
                        options = {
                            subText: `Next Step: Get Preapproved to gain a competitive advantage over other buyers!`,
                            el: 'MRC Mortgage Opt_in',
                            cta_el: 'MRC Post RE Click',
                            treatment: 'cw-10250_on8',
                        };
                    }
                    this.$eventBus.$emit('dialog-center', {
                        name: 'mvtIntentfulTYP',
                        trigger: this.componentTrigger,
                        opt: options,
                    });
                    return;
                }

                if (popName) {
                    this.$eventBus.$emit('dialog-center', {
                        name: popName,
                        trigger: this.componentTrigger,
                        opt: {},
                    });
                }
                if (!popName) {
                    this.resetPrevHotlead();
                }
            }, 100);
        },
        hide() {
            this.componentName = '';
            this.componentOpt = {};
            this.componentTemplate = '';
            this.componentOpt.events && this.componentOpt.events.closed && this.componentOpt.events.closed();

            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                this.afterLoginAndCloseDialog();
            }, 3000);
        },
        showMessage(message) {
            this.message = message;
            this.showTip = true;
            if (tipTimer) {
                clearTimeout(tipTimer);
            }
            tipTimer = setTimeout(() => {
                this.hideMessage();
            }, 1500);
        },
        hideMessage() {
            this.showTip = false;
        },
        facebookLogin() {
            var listingId = window.addFavListingId || '';
            var propertyId = window.addFavPropertyId || '';
            this.$eventBus.$emit('eventbus-context-update');
            var url = location.origin + location.pathname;
            url = this.glb.appUrl + 'loginFacebookDialog/?returnUrl=' + encodeURIComponent(url) + '&listingId=' + listingId + '&propertyId=' + propertyId + '&userType=' + this.glb.pageInfo.userType;
            window.location.href = url;
        },
        appleLogin(trigger, requestName) {
            $.loadAppleAuth().then(() => {
                var parseJwtCode = function (code) {
                    let url = code.split('.')[1];
                    let data = url.replace(/-/g, '+').replace(/_/g, '/');
                    let jsonData = decodeURIComponent(
                        atob(data)
                            .split('')
                            .map(function (c) {
                                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                            })
                            .join('')
                    );

                    return JSON.parse(jsonData);
                };

                document.addEventListener('AppleIDSignInOnSuccess', (data) => {
                    if (!data || !data.detail || !data.detail.authorization) {
                        return;
                    }
                    let appleData = {},
                        tokenData = parseJwtCode(data.detail.authorization.id_token);

                    appleData.idtoken = data.detail.authorization.id_token;
                    appleData.authorizationCode = data.detail.authorization.code;
                    appleData.email = tokenData.email;
                    appleData.socialid = tokenData.sub;
                    appleData.requesterType = 'CONSUMER_DT';

                    if (data.detail.user) {
                        appleData.name = data.detail.user.name.firstName + ' ' + data.detail.user.name.lastName;
                    }
                    appleData.trigger = getGa4Trigger(trigger, requestName);
                    appleData.userType = this.glb.pageInfo.userType;
                    this.loginForAppleAccount(appleData).then((res) => {
                        this.loginSuccess(res);
                    });
                });
                document.addEventListener('AppleIDSignInOnFailure', (error) => {});
                if (window.AppleID) {
                    window.AppleID.auth.init({
                        clientId: this.glb.appleLoginClientId,
                        scope: 'name email',
                        redirectURI: window.__INITIAL_STATE__.appUrl,
                        usePopup: true,
                    });
                    window.AppleID.auth.signIn();
                }
            });
        },
        loginSuccess(res) {
            if (res && res.status && res.status.code === 0 && res.data) {
                this.$eventBus.$emit('close-dialog', this.componentTrigger);
                this.$eventBus.$emit('eventbus-context-update');
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }
        },
        click3rdParty(setting, options) {
            $.click3rdParty(setting, options);
        },
        show3rdPartyPop({
            id,
            url,
            zipcode,
            price,
            propertyType,
            pid,
            houseRealStatus,
            trigger,
            cta,
            isAutoPop,
            imageUrl,
            listingUrl,
            veteranTYPSplit,
            veteransType,
            isAppendUtmCta,
            isNoLenders,
            hotleadId,
        }) {
            const options = {
                triggerId: id || 'getquotes',
                trigger: trigger || '',
                zipcode: zipcode || this.glb.geo.zipcode || '',
                price: price || '',
                propertyType: propertyType || '',
                houseRealStatus: houseRealStatus || '',
                address: encodeURIComponent(this.glb.geo.address) || '',
                city: encodeURIComponent(this.glb.geo.city) || '',
                state: encodeURIComponent(this.glb.geo.state) || '',
                county: encodeURIComponent(this.glb.geo.county) || '',
                fullName: this.glb.hotleadInfo.fullName || this.glb.user.fullName || '',
                email: this.glb.hotleadInfo.email || this.glb.user.email || '',
                phone: this.glb.hotleadInfo.phone || this.glb.user.phone || '',
                url: url || '',
                pageType: this.glb.pageType || '',
                cta: cta || '',
                isAutoPop: isAutoPop || false,
                imageUrl: encodeURIComponent(imageUrl) || '',
                listingUrl: encodeURIComponent(listingUrl) || '',
                veteranTYPSplit: veteranTYPSplit || false,
                veteransType: veteransType || '',
                isNoLenders: isNoLenders || false,
                hotleadId: hotleadId || '',
            };
            options.phone = options.phone.replace(/[\D]/g, '');
            if (pid) {
                options.pid = pid;
            }
            if (isAppendUtmCta) {
                options.isAppendUtmCta = isAppendUtmCta;
            }

            const mobilwMRCTreatment = [
                {
                    utm_campaign: 'apply_mobile',
                    trigger: 'apply',
                },
                {
                    utm_campaign: 'getprequalified_mobile',
                    trigger: 'calcfindalender',
                },
                {
                    utm_campaign: 'prequalify_mobile',
                    trigger: 'prequalify',
                },
                {
                    utm_campaign: 'findlender_mobile',
                    trigger: 'findalender',
                },
                {
                    utm_campaign: 'getpreapproved_mobile',
                    trigger: 'getpreapproved',
                },
            ];

            let MRCSplitForMobileProperty =
                ['MOBILE'].includes(options.propertyType) &&
                mobilwMRCTreatment.find((item) => {
                    if (options.triggerId === item.trigger) {
                        return true;
                    }
                });
            if (MRCSplitForMobileProperty) {
                this.openMRCPopup(MRCSplitForMobileProperty, options.cta);
                return;
            }
            const MRCTreatment = [
                {
                    split: 'movoto-cpc-apply-cw-9238',
                    treatment: 'on9',
                    utm_campaign: 'getpreapprovedATF',
                    trigger: 'apply',
                    desc: 'getpreapproved',
                    cta: 'Get Preapproved ATF',
                },
                {
                    split: 'movoto-cpc-preapproved-cw-9240',
                    treatment: 'on6',
                    utm_campaign: 'getpreapproved',
                    trigger: 'getpreapproved',
                    desc: 'getpreapproved',
                },
                {
                    split: 'movoto-cpc-preapproved-cw-9240',
                    treatment: 'on6',
                    utm_campaign: 'getpreapproved',
                    trigger: 'calcfindalender',
                    desc: 'getpreapproved',
                },
                {
                    split: 'movoto-cpc-preapproved-cw-9240',
                    treatment: 'on10',
                    utm_campaign: 'getpreapproved3T',
                    trigger: 'calcfindalender',
                    desc: 'getpreapproved',
                    cta: 'Get Preapproved_3CTAtest',
                },
                {
                    split: 'movoto-cpc-preapproved-cw-9240',
                    treatment: 'on10',
                    utm_campaign: 'getpreapproved3T',
                    trigger: 'getpreapproved',
                    desc: 'getpreapproved',
                    cta: 'Get Preapproved_3CTAtest',
                },
                {
                    split: 'movoto-cpc-lender-cw-9242',
                    treatment: 'on6',
                    utm_campaign: 'findalender',
                    trigger: 'findalender',
                    desc: 'findlender',
                },
                {
                    split: 'movoto-cpc-lender-cw-9242',
                    treatment: 'on10',
                    utm_campaign: 'findalender3T',
                    trigger: 'findalender',
                    desc: 'findlender',
                    cta: 'Find a lender_3CTAtest',
                },
            ];

            let MRCSplit = MRCTreatment.find((item) => {
                if (options.triggerId === item.trigger && this.getSplit(item.split, item.treatment)) {
                    return true;
                }
            });
            if (MRCSplit) {
                this.openMRCPopup(MRCSplit, MRCSplit.cta || options.cta);
                return;
            }
            const splitsTreatment = [
                {
                    trigger: 'compareMortRates',
                    pid: 'movoto_listingsapi',
                },
                {
                    split: 'movoto-cpc-preapproved-cw-9240',
                    treatment: 'on4',
                    trigger: 'calcfindalender',
                    pid: 'movoto_listingsapi',
                },
            ];
            let splitItem = splitsTreatment.find((item) => {
                if (
                    (options.triggerId === item.trigger && (this.getSplit(item.split, item.treatment) || this.getSplit(item.split, 'default') || this.getSplit(item.split, 'off'))) ||
                    id === 'compareMortRates'
                ) {
                    return true;
                }
            });
            if (splitItem) {
                this.openMortgagePopUp(splitItem.trigger, splitItem.pid, splitItem.cta || options.cta);
                return;
            }
            //all the default case will openBankratePop
            $.openBankratePop(options);
        },
        openMRCPopup(item, cta) {
            this.$eventBus.$emit('dialog-center', {
                name: 'mrcMortgageRates',
                trigger: this.componentTrigger,
                opt: {
                    showNormalMrc: true,
                    utm_campaign: item.utm_campaign,
                    desc: item.desc,
                    cta: cta,
                    ga: 'partner_mortgage_listings_session',
                },
            });
        },
        openMortgagePopUp(trigger, pid, cta) {
            this.$eventBus.$emit('dialog-center', {
                name: '3wayPayment',
                trigger: this.componentTrigger,
                opt: {
                    showRefinance: true,
                    pid: pid,
                    triggerId: trigger,
                    cta: cta,
                },
            });
        },
        dialogAllClosed() {
            if (this.glb.authRedirectUrl) {
                window.location.href = this.glb.authRedirectUrl;
            } else {
                let options = this.getPrevHotlead.options;
                if (options) {
                    if (options.redirectUrl) {
                        window.location = options.redirectUrl;
                    } else if (options.newUser) {
                        window.location.reload();
                    }
                }
            }
            this.resetPrevHotlead();
        },
        showConfirmPop() {
            this.$eventBus.$emit('dialog-center', {
                name: 'mvtConfirmAuth',
                opt: {},
                trigger: this.componentTrigger,
            });
        },
        openSchedulePop() {
            let hotleadOpt = {
                hotleadTitle: 'Meet an experienced local agent',
                hotleadSub: 'Talk to one of our expert agents,<br/> no obligation, just helpful advice.',
                hotleadType: 'ChatBotBuy',
                buttonText: 'Request Info',
                profileUrl: this.glb.cdnUrl + 'vimages/agent-headshot.png',
                showInterestOptions: true,
            };
            if (this.dpp && this.dpp.geo && this.dpp.geo.zipcode) {
                hotleadOpt.propertyData = this.dpp;
                hotleadOpt.zipcode = this.dpp.geo.zipcode;
            } else if (this.glb.geo && this.glb.geo.zipcode) {
                hotleadOpt.zipcode = this.glb.geo.zipcode;
            } else {
                hotleadOpt.showZipcode = true;
            }

            this.$eventBus.$emit('dialog-center', {
                name: 'mvtHotleadForm',
                trigger: this.componentTrigger,
                opt: hotleadOpt,
            });
        },
        popHotleadAfterAutenticated() {
            let hasSendHotlead = $.getStorage('hotleadCount') > 0;
            let autoPopAfterAuthenticated = $.getStorage('autoPopAfterAuthenticated');

            if (!autoPopAfterAuthenticated || hasSendHotlead) {
                return;
            }

            if (this.getSplit('movoto-CW-1073')) {
                if (this.glb.user && !this.glb.user.activated) {
                    this.openSchedulePop();
                }
            } else {
                this.openSchedulePop();
            }

            setTimeout(() => {
                $.setStorage('autoPopAfterAuthenticated', false);
            }, 3000);
            this.$eventBus.$once('eventbus-dialog-close', () => {
                $.setStorage('autoPopAfterAuthenticated', false);
                this.$eventBus.$emit('eventbus-hotlead-step', { trigger: 'mvtAuthenticate', requestName: this.requestName });
            });
        },
        setRedirectUrl() {
            let url = window.location.href.split('#')[0];
            //get returnurl from current href
            let search = url.split('?')[1];
            //get value of returnurl
            let redirectUrl = window.location.origin + window.location.pathname;
            if (search) {
                let params = search.split('&');
                for (let i = 0; i < params.length; i++) {
                    let param = params[i].split('=');
                    if (/returnurl/i.test(param[0])) {
                        redirectUrl = decodeURIComponent(param[1]);
                        break;
                    }
                }
            }

            this.glb.authRedirectUrl = redirectUrl;
        },
    },
    mounted() {
        if (this.getSplit('movoto-CW-1381') && !this.removeAds) {
            $.loadCmAds();
        }
        this.popHotleadAfterAutenticated();
        this.$eventBus.$on('eventbus-hotlead-step', this.nextHotleadStep);
        this.$eventBus.$on('eventbus-login-apple', this.appleLogin);
        this.$eventBus.$on('eventbus-login-facebook', this.facebookLogin);
        this.$eventBus.$on('eventbus-pop-3rdparty', this.show3rdPartyPop);
        this.$eventBus.$on('eventbus-click-3rdparty', this.click3rdParty);
        this.$eventBus.$on('eventbus-submit-signup', ({ email, requestName, phone, name }) => this.signUpMixin(email, requestName, phone, name));
        this.$eventBus.$on('eventbus-show-confirm-pop', this.showConfirmPop);
        this.$eventBus.$on('eventbus-authenticated', (requestName) => {
            this.requestName = requestName;
            if (requestName !== 'simpledppclaim' && requestName !== 'addclaimhome') {
                let isRentalPage = (this.glb.pageType === 'vmapsearch' || this.glb.pageType === 'vdpp') && this.glb.rentals;

                if (isRentalPage) {
                    this.$eventBus.$emit('close-dialog', this.componentTrigger);
                } else {
                    $.setStorage('autoPopAfterAuthenticated', true);
                }
                this.popHotleadAfterAutenticated();
            }
            this.setRedirectUrl();
            this.afterLoginAndCloseDialog();
        });
        this.$eventBus.$on('eventbus-pop-schedule-lead', this.openSchedulePop);
        // this.openSchedulePop();
    },
    beforeUnmount() {
        this.$eventBus.$off('dialog-center');
    },
    beforeMount() {
        this.$eventBus.$on('dialog-center', (data) => {
            this.show(data.name, data.opt, data.trigger);
        });
        this.$eventBus.$on('dialog-center-tip', (message) => {
            this.showMessage(message);
        });
    },
};
